import axios from 'axios'
import store from '@/store/index.js'
import Router from '@/router/index.js'

import { Message } from 'element-ui'

axios.defaults.timeout = 0
let showMsg = true
if (process.env.NODE_ENV == 'development') {
  //如果是dev环境
  // axios.defaults.baseURL = 'http://192.168.28.193:8500'
  axios.defaults.baseURL = '/api' // 本地环境
  // axios.defaults.baseURL = '/' // 正式环境
  // axios.defaults.baseURL = 'http://192.168.43.188:8500'
} else {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
}
//axios.defaults.withCredentials = true;

// Http's request 拦截器
axios.interceptors.request.use(
  (config) => {
    if (store.state.user.userInfo.token) {
      try {
        delete config.data.createDate
        delete config.data.modifyDate
      } catch (e) {}
      config.headers['token'] = store.state.user.userInfo.token
    } else {
      Router.push('/login')
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
axios.interceptors.response.use(
  (data) => {
    //接受文件流无返回code，特殊判断。
    if (data.headers['content-type']&&data.headers['content-type'].includes('application/vnd.ms-excel')) {
      return Promise.resolve(data.data)
    }

    if (data.status != 200) {
      return Promise.reject(err)
      Message.error(data.status)
    } else {
      if (data.data.code == 200) {
        return Promise.resolve(data.data)
      } else if (data.data.code == 509) {
        // 只提示一次
        if (showMsg) {
          Message.error('登录超时，请重新登录')
          showMsg = false
          setTimeout(() => {
            showMsg = true
          }, 5000)
        }
        Router.push('/login')
        return Promise.reject(data)
      } else if (data.data.code == 0) {
        Message.success(data.data.msg)
      } else if (data.data.code == -1 || data.data.code == 500) {
        Message.error(data.data.msg)
        return Promise.reject(data)
      } else if (data.data.code == 400 ) {
        Message.error('请求错误')
        return Promise.reject(data)
      }else if (data.status==200) {//文件压缩下载
        return Promise.resolve(data.data)
      }
       else {
        Message.error('请求错误')
        return Promise.reject(data)
      }
    }
  },
  (err) => {
    Message.error('请求错误')
    return Promise.reject(err)
  }
)
export default axios
