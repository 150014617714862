import Axios from '@/axios'

//创建商务信息
export const addBusinessManage = function (cond = {}) {
  return Axios.post('/businessManage/addBusinessManage', cond)
}

//商务信息跟踪
export const addBusinessTrace = function (cond = {}) {
  return Axios.post('/businessManage/addBusinessTrace', cond)
}
//指派用户
export const addUserAssignment = function (cond = {}) {
  return Axios.post('/businessManage/addUserAssignment', cond)
}

//审核商务信息
export const updateBusinessManageStatus = function (cond = {}) {
  return Axios.post('/businessManage/updateBusinessManageStatus', cond)
}

//获取商务信息申请列表
export const getApplyForList = function (cond = {}) {
  return Axios.post('/businessManage/getApplyForList', cond)
}

//获取商务信息审核列表
export const getBusinessManageList = function (cond = {}) {
  return Axios.post('/businessManage/getBusinessManageList', cond)
}

//审核商务信息
export const updateBusinessManage = function (cond = {}) {
  return Axios.post('/businessManage/updateBusinessManage', cond)
}

//审核商务信息
export const auditBusinessManage = function (cond = {}) {
  return Axios.post('/businessManage/auditBusinessManage', cond)
}

//获取商务信息详情
export const getBusinessManage = function (cond) {
  return Axios.post('/businessManage/getBusinessManage/' + cond)
}

//获取通过得商务信息
export const getDisposeList = function (cond = {}) {
  return Axios.post('/businessManage/getDisposeList', cond)
}

//获取通过得商务信息
export const getUserAssignment = function (cond) {
  return Axios.post('/businessManage/getUserAssignment/' + cond)
}

//获取商务跟踪信息
export const getDisposeInfo = function (cond) {
  return Axios.post('/businessManage/getDisposeInfo/' + cond)
}

//获取商务信息立项列表
export const getCreateProjectList = function (cond = {}) {
  return Axios.post('/businessManage/getCreateProjectList', cond)
}

//获取指派用户列表
export const getUserAssignmentList = function (cond = {}) {
  return Axios.post('/businessManage/getUserAssignmentList', cond)
}

// //获取商务费用审核列表--废弃
// export const getAuditList = function (cond = {}) {
//   return Axios.post('/businessCost/getAuditList', cond)
// }

// //商务费用报销审核--废弃
// export const auditBusinessCost = function (cond = {}) {
//   return Axios.post('/businessCost/auditBusinessCost', cond)
// }

// //获取当前用户可以申请报销得商务名称列表--废弃
// export const getBusinessNameList = function (cond = {}) {
//   return Axios.post('/businessCost/getBusinessNameList', cond)
// }

// //获取当前用户报销得附件信息--废弃
// export const getBusinessCost = function (cond) {
//   return Axios.post('/businessCost/getBusinessCost/' + cond)
// }

// //提交审核申请--废弃
// export const addBusinessCost = function (cond = {}) {
//   return Axios.post('/businessCost/addBusinessCost', cond)
// }

// //用户申请的报销列表--废弃
// export const getCostApplyForList = function (cond = {}) {
//   return Axios.post('/businessCost/getApplyForList', cond)
// }

// //用户申请报销详情--废弃
// export const getBusinessCostDetailed = function (cond) {
//   return Axios.post('/businessCost/getBusinessCostDetailed/' + cond)
// }

// //报销通过列表（财务查看）--废弃
// export const auditPassList = function (cond = {}) {
//   return Axios.post('/businessCost/auditPassList', cond)
// }

// //财务已报销--废弃
// export const affirmApply = function (cond) {
//   return Axios.post('/businessCost/affirmApply/' + cond)
// }

//全部商务
export const getAllBusinessManageList = function (cond = {}) {
  return Axios.post('/businessManage/getAllBusinessManageList', cond)
}

//用户申请的报销列表
export const businessManageTransferProject = function (cond = {}) {
  return Axios.post('/businessManage/businessManageTransferProject', cond)
}

//指派负责人列表
export const getPrincipalUserList = function (cond = {}) {
  return Axios.post('/businessManage/getPrincipalUserList', cond)
}

//商务失败列表
export const businessManageFailList = function (cond = {}) {
  return Axios.post('/businessManage/businessManageFailList', cond)
}

//导出商务报销
// export const exportReport = function (cond) {
//   return Axios({
//     url: "/businessCost/exportReport/"+cond,
//     method: "get",
//   });
// }
export const exportReport = function (cond) {
  return Axios({
    url: '/businessCost/exportReport/' + cond,
    method: 'get',
    responseType: 'arraybuffer',
  })
}

//全部商务导出
export const allBusinessManageListExpor = function (cond = {}) {
  return Axios({
    url: '/businessManage/allBusinessManageListExpor',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//删除商务
export const deleteBusiness = function (cond) {
  return Axios.post('/businessManage/delete/' + cond)
}

//获取全部商务名称
export const getNameListNoPage = function (cond = {}) {
  return Axios.post('/businessManage/nameListNoPage', cond)
}

//获取全部不分页商务负责人
export const principalUserNameListNoPage = function (cond = {}) {
  return Axios.post('businessManage/principalUserNameListNoPage', cond)
}

//商务指派用户导出
export const businessUserAssignmentExpor = function (cond = {}) {
  return Axios({
    url: '/businessManage/businessUserAssignmentExpor',
    method: 'post',
    responseType: 'blob',
    data: cond,
  })
}

//商务终结
export const finality = function (cond) {
  return Axios.post('/businessManage/finality/', cond)
}

//暂停商务
export const pause = function (cond) {
  return Axios.post('/businessManage/pause/' + cond)
}

//商务移交计划经营部-可编辑
export const handOver = function (cond = {}) {
  return Axios.post('/businessManage/handOver', cond)
}

//张浩新增用于全部商务列表接口
export const getNewAllBusinessManageList = function (cond) {
  return Axios.post('/businessManage/getNewAllBusinessManageList', cond)
}

// 查商务结算费用-查调整绩效数据
export const selectAdjustmentDetailByBusinessId = function (cond) {
  return Axios.post('businessPerformance/selectAdjustmentDetailByBusinessId/' + cond)
}

//商务结算费用-保存
export const updateAdjustment = function (cond = {}) {
  return Axios.post('/businessPerformance/updateAdjustment', cond)
}

//查商务结算费用--log列表
export const listLog = function (cond = {}) {
  return Axios.post('/businessPerformance/listLog', cond)
}

// 查商务结算费用-查看log详情
export const selectLogById = function (cond) {
  return Axios.post('/businessPerformance/selectLogById/' + cond)
}

// 商务统计-获取商务名称列表
export const getBusinessInfo = function (cond = '') {
  return Axios.get('/businessManage/getBusinessInfo' + cond)
}
