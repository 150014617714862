import Axios from '@/axios'

// 获取投标管理能关联的公共费用
export const getAllSpendingPaymentList = function (cond) {
  return Axios.post('/bidManagement/getAllSpendingPaymentList', cond)
}

// 添加投标信息
export const addBid = function (cond) {
  return Axios.post('/bidManagement/add', cond)
}

// 查看投标信息详情
export const selectDetailBid = function (cond) {
  return Axios.post('/bidManagement/selectDetailById/' + cond)
}

// 删除投标信息
export const deleteBid = function (cond) {
  return Axios.post('/bidManagement/delete/' + cond)
}

// 添加投标人员信息
export const addMember = function (cond) {
  return Axios.post('/bidManagement/addMember', cond)
}
// 投标信息列表
export const getBidList = function (cond) {
  return Axios.post('/bidManagement/getBidList', cond)
}
// 投标列表总费用
export const getSumCost = function (cond) {
  return Axios.post('/bidManagement/sumCost', cond)
}
// 投标详情关联商务
export const getBusinessList = function (cond) {
  return Axios.post('/businessManage/getNewAllBusinessManageList', cond)
}
// 保证金退回
export const bidSecurityDeposit = function (cond) {
  return Axios.post('/bidManagement/bidSecurityDeposit', cond)
}
// 投标认领列表
export const getBidLeaderList = function (cond) {
  return Axios.post('/bidManagement/getBidLeaderList', cond)
}

// 投标成员规则
export const getMemberList = function (cond) {
  return Axios.post('/bidManagement/allList', cond)
}

// 投标名称
export const nameListNoPage = function (cond = {}) {
  return Axios.post('/bidManagement/nameListNoPage', cond)
}
