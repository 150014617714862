import Axios from '@/axios'
// 转审
export const taskSetAssignee = function (cond = {}) {
  return Axios.post('/actTransfer/taskSetAssignee', cond)
}
// 新增
export const addTaskSetAssignee = function (cond = {}) {
  return Axios.post('/actTransfer/addTaskSetAssignee', cond)
}
// 更改审核接口通过--新(通用)
export const disposeTask = function (cond = {}) {
  return Axios.post('/actTransfer/disposeTask', cond)
}

// 更改审核接口通过--新(项目付款)
export const disposeTaskProjectPayment = function (cond = {}) {
  return Axios.post('/projectPaymentApplication/disposeTask', cond)
}

// 后补发票审批-不通过
export const lateInvoiceApprovalReject = function (cond = {}) {
  return Axios.post('/projectPaymentApplication/lateInvoiceApprovalReject', cond)
}

