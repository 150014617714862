import Axios from '@/axios'
// 提交
export const startProcess = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/startProcess', cond)
}

// 个人填报列表
export const getApplyForList = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/getApplyForList', cond)
}

// 查看详情
export const selectDetailById = function (cond) {
  return Axios.post('/businessPaymentApplication/selectDetailById/' + cond)
}

// 暂存编辑
export const undeterminedEdit = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/undeterminedEdit', cond)
}

// 暂存
export const tempAdd = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/add', cond)
}
// 删除
export const deleteExpense = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/delete', cond)
}
// 撤回
export const reject = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/reject', cond)
}
// 驳回提交
export const resubmitByReject = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/resubmitByReject', cond)
}

// 审批列表
export const searchTask = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/searchTask', cond)
}
// 审批不通过
export const approvalReject = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/approvalReject', cond)
}

// 财务确认报销
export const affirmApply = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/affirmApply', cond)
}
//导出
export const exporting = function (cond) {
  return Axios({
    url: '/businessPaymentApplication/export/' + cond,
    method: 'get',
    responseType: 'blob',
  })
}
//明细列表
export const allList = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/allList', cond)
}
//统计值
export const getApplyStatistics = function (cond = {}) {
  return Axios.post('/businessPaymentApplication/getApplyStatistics', cond)
}
//获取当前用户可以申请商务付款申请的商务名称列表
export const getBusinessNameListByPrincipal = function (cond) {
  return Axios.post('/businessManage/getBusinessNameListByPrincipal', cond)
}
// 后补发票
export const lateInvoice = function (cond) {
  return Axios.post('/businessPaymentApplication/lateInvoice', cond)
}

// 后补发票不通过
export const lateInvoiceApprovalReject = function (cond) {
  return Axios.post('/businessPaymentApplication/lateInvoiceApprovalReject', cond)
}
